/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import CopyableInput from '../../../components/CopyableInput'
import InputField from '../../../components/InputField'
import SelectField from '../../../components/SelectField'
import Table from '../../../components/Table'
import TableButton from '../../../components/TableButton'
import UsersListPagination from '../../../components/TablePagination'
import { FileUploadToFGGroup } from '../../../Functions/FGGroup'
import { GetEmployees, UpdateEmployee } from '../../../Functions/FGGroup/Employee'

const HRMemberDetail = () => {
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const employee_id: any = searchParams.get('employee_id')
	const [loading, setLoading] = useState(false)
	const [documentLoading, setDocumentLoading] = useState(false)
	const [sort, setSort] = useState('createdAt')
	const [sortOrder, setSortOrder] = useState<QuerySortOptions>('desc')
	const [visibleDetails, setVisibleDetails] = useState<string | null>(null)
	const [memberData, setMemberData] = useState<any>([])
	const [memberDetailData, setMemberDetailData] = useState<any>({
		admin_id: '',
		employee_code: '',
		name: '',
		photo: '',
		position: '',
		mobile: '',
		bank_name: '',
		account_details: '',
		IFSC: '',
		email: '',
		salary: '',
		account_detail: '',
		address: '',
	})
	const [memberDetailAddData, setMemberDetailAddData] = useState({
		document_type: '',
		upload_photo: '',
		file_url: '',
		editImage: false,
		selectedFile: null as File | null,
	})
	const [pagination, setPagination] = useState({
		page: 1,
		itemsPerPage: 10,
	})

	const fetchEmployeeData = async () => {
		setLoading(true)
		try {
			const response: any = await GetEmployees({ id: employee_id })
			const filteredData: any = response.data[0]
			setMemberDetailData({
				admin_id: filteredData?._id,
				employee_code: filteredData?.employee?.employee_code,
				name: filteredData?.full_name,
				photo: filteredData?.employee?.photo,
				position: filteredData?.employee?.position,
				mobile: filteredData?.mobile,
				bank_name: filteredData?.employee?.account_details?.bank_name,
				account_details: filteredData?.employee?.account_details?.account_number,
				IFSC: filteredData?.employee.account_details.IFSC,
				email: filteredData?.email,
				salary: filteredData?.employee?.salary,
				address: filteredData?.employee?.address,
				document: filteredData?.employee?.document,
				proof: filteredData?.employee?.proof,
			})

			setMemberData(filteredData?.employee?.document)
		} catch (error) {
			console.error(error)
		}
		setLoading(false)
	}

	useEffect(() => {
		fetchEmployeeData()
	}, [])

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target

		setMemberDetailData((prevData: any) => ({
			...prevData,
			[name]: value,
		}))
	}

	const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { id, name, value } = event.target
		if (id === 'fileInput' && event.target instanceof HTMLInputElement && event.target.files) {
			const file = event.target.files[0]
			setMemberDetailAddData((prevData) => ({
				...prevData,
				selectedFile: file,
				file_url: URL.createObjectURL(file),
				editImage: true,
			}))
		}
		setMemberDetailAddData((prevData) => ({
			...prevData,
			[name]: value,
		}))
	}

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, files } = event.target as HTMLInputElement
		if (files && files[0]) {
			setMemberDetailData((prevData: any) => ({
				...prevData,
				[name]: files[0],
			}))
		}
	}

	const sortableFields = [
		{ title: 'Document Type', field: 'document_type' },
		{ title: 'Preview', field: 'link' },
		// { title: 'Document Link', field: 'link' },
	]

	const handleSortChange = (newSort: string, newSortOrder: QuerySortOptions) => {
		setSort(newSort)
		setSortOrder(newSortOrder)
	}

	const handleRowClick = (id: string) => {
		if (window.innerWidth <= 1024) {
			setVisibleDetails(visibleDetails === id ? null : id)
		}
	}

	const handleUpdateDocument = async (data: any) => {
		setDocumentLoading(true)
		try {
			let imgUrl = ''

			if (data === 'Document') {
				if (memberDetailAddData.selectedFile) {
					const imageUrl: any = await FileUploadToFGGroup([memberDetailAddData.selectedFile], {
						directory: 'books',
					})
					imgUrl = imageUrl.data?.fileURLs[0]
					toast.success('Document uploaded successfully')
				}
			}

			const {
				admin_id,
				employee_code,
				name,
				position,
				salary,
				mobile,
				bank_name,
				account_details,
				IFSC,
				email,
				address,
			} = memberDetailData

			const payload: any = {
				admin_id,
				type: 'Employee',
				employee_code,
				name,
				position,
				salary,
				mobile,
				account_details: {
					bank_name,
					account_number: account_details,
					IFSC,
				},
				email,
				address,
				document: [
					...memberData,
					{
						document_type: memberDetailAddData.document_type,
						link: imgUrl,
					},
				],
			}

			await UpdateEmployee(payload)

			if (data === 'Document') {
				toast.success('Document Updated Successfully')
			} else {
				toast.success('Employee Data Updated Successfully')
			}

			// Clear form data after successful update
			setMemberDetailAddData({
				document_type: '',
				upload_photo: '',
				file_url: '',
				editImage: false,
				selectedFile: null,
			})

			fetchEmployeeData()
		} catch (error: any) {
			toast.error(error.message)
			console.error(error)
		}
		setDocumentLoading(false)
	}

	const handlePageChange = (page: number) => {
		setPagination({ ...pagination, page })
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>Employee Detail</PageTitle>
			<>
				<div className='card pb-3'>
					<div className='card-body'>
						<h1 className='fw-bold text-dark fs-1 mb-6 mt-3 '>Member Detail</h1>

						<div className='row'>
							<div className='col-md-3 d-md-block d-flex mb-md-0 mb-5'>
								<div>
									<label
										htmlFor='proof'
										className='fw-bold ms-md-0 ms-6 fs-6 mb-md-5 mb-2'>
										Profile :
									</label>
									<br />
									<div className=' d-md-block d-flex justify-content-center'>
										<img
											src={'https://files.fggroup.in/' + memberDetailData?.photo}
											className='w-75'
											style={{ borderRadius: '5px' }}
											alt='Image preview'
										/>
									</div>
								</div>
								<div className='mt-md-5'>
									<label
										htmlFor='proof'
										className='fw-bold ms-md-0 ms-6 fs-6 mb-md-5 mb-2'>
										Proof :
									</label>
									<br />
									<div className=' d-md-block d-flex justify-content-center'>
										<img
											src={'https://files.fggroup.in/' + memberDetailData?.proof}
											className='w-75'
											alt='Image preview'
											style={{ borderRadius: '5px' }}
										/>
									</div>
								</div>
							</div>
							<div className='col-md-9'>
								<div className='row'>
									<div className='col-md-4'>
										<InputField
											className='col-12 fv-row'
											placeholder='Employee Code'
											type='text'
											name='employee_code'
											label='Employee Code'
											htmlFor='employee_code'
											value={memberDetailData.employee_code}
											onChange={handleInputChange}
										/>
									</div>
									<div className='col-md-4'>
										<InputField
											className='col-12 fv-row'
											placeholder='Name'
											type='text'
											name='name'
											label='Name'
											htmlFor='name'
											value={memberDetailData.name}
											onChange={handleInputChange}
										/>
									</div>
									<div className='col-md-4'>
										<InputField
											className='col-12 fv-row'
											placeholder='Position'
											type='text'
											name='position'
											label='Position'
											htmlFor='position'
											value={memberDetailData?.position}
											onChange={handleInputChange}
										/>
									</div>
									<div className='col-md-4'>
										<InputField
											className='col-12 fv-row'
											placeholder='Mobile'
											type='text'
											name='mobile'
											label='Mobile'
											htmlFor='mobile'
											value={memberDetailData.mobile}
											onChange={handleInputChange}
										/>
									</div>
									<div className='col-md-4'>
										<InputField
											className='col-12 fv-row'
											placeholder='Email'
											type='email'
											name='email'
											label='Email'
											htmlFor='email'
											value={memberDetailData.email}
											onChange={handleInputChange}
										/>
									</div>
									<div className='col-md-4'>
										<InputField
											className='col-12 fv-row'
											placeholder='Salary'
											type='text'
											name='salary'
											label='Salary'
											htmlFor='salary'
											value={memberDetailData.salary}
											onChange={handleInputChange}
										/>
									</div>
									<div className='col-md-12'>
										<InputField
											className='col-12 fv-row'
											placeholder='Address'
											type='text'
											name='address'
											label='Address'
											htmlFor='address'
											value={memberDetailData.address}
											onChange={handleInputChange}
										/>
									</div>

									<div className='col-md-12 mt-3'>
										<h2 className='mb-5'>Account Details:</h2>
										<div className='row'>
											<div className='col-md-4 fv-row mb-md-7'>
												<CopyableInput
													placeholder='Bank Name'
													type='text'
													className='col-12 fv-row mb-7'
													name='bank_name'
													label='Bank Name'
													htmlFor='bank_name'
													value={memberDetailData.bank_name}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-4 fv-row mb-md-7'>
												<CopyableInput
													placeholder='Account No.'
													type='text'
													className='col-12 fv-row mb-7'
													name='account_details'
													label='Account No.'
													htmlFor='account_details'
													value={memberDetailData.account_details}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-4 fv-row mb-md-7'>
												<CopyableInput
													placeholder='IFSC Code'
													type='text'
													className='col-12 fv-row mb-7'
													name='IFSC'
													label='IFSC Code'
													htmlFor='IFSC'
													value={memberDetailData.IFSC}
													onChange={handleInputChange}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-12 d-flex justify-content-end'>
								<TableButton
									action='edit'
									onClick={() => handleUpdateDocument('Employee')}
									text='Save Changes'
									backgroundDark={true}
								/>
							</div>
						</div>
					</div>
				</div>
				{documentLoading && (
					<div className='loader-background'>
						<div
							className='spinner-border spinner-border-1 text-white'
							style={{ width: '50px', height: '50px' }}
							role='status'>
							<span className='visually-hidden'>Loading...</span>
						</div>
					</div>
				)}
				<div className='row'>
					<div className='col-12 mt-7'>
						<div className='card py-10'>
							<div className='card-body'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='row'>
											<div className='col-md-4 fv-row mb-7'>
												<SelectField
													className='col-12 fv-row'
													name='document_type'
													label='Document Type'
													htmlFor='document_type'
													value={memberDetailAddData.document_type}
													onChange={handleSelectChange}
													options={[
														'Joining Letter ',
														'Termination Letter ',
														'HR Agreement ',
														'Experience Letter ',
														'Google Pay QR Code',
													]}
												/>
											</div>
											<div className='col-md-4 fv-row mb-md-7'>
												<InputField
													placeholder='Upload Photo'
													type='file'
													className='col-12 fv-row mb-md-7'
													name='upload_photo'
													id='fileInput'
													label='Upload Photo'
													htmlFor='upload_photo'
													value={memberDetailAddData.upload_photo}
													onChange={(e) => handleSelectChange(e)}
												/>
											</div>

											<div className='col-md-4 fv-row mb-7 d-flex align-items-center justify-content-center'>
												<button
													type='button'
													onClick={() => handleUpdateDocument('Document')}
													className='btn btn-success mx-2 mt-md-7 my-4'>
													Upload Document
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className='table-responsive'>
									<Table
										data={memberData}
										columns={sortableFields}
										sort={sort}
										sortOrder={sortOrder}
										onSortChange={handleSortChange}
										removeAction={true}
										renderRow={(
											member: any,
											index: number,
											actualIndex: number,
											isVisible: boolean
										) => {
											const isPdf = member.link.endsWith('.pdf')
											const fileUrl = 'https://files.fggroup.in/' + member.link
											return (
												<React.Fragment key={member._id}>
													<tr
														onClick={() => handleRowClick(member._id)}
														className='data-row'>
														<td className='text-center'>
															<div className='d-flex'>
																<FontAwesomeIcon
																	icon={faPlusCircle}
																	className='mx-2 ms-5 mb-1 plus-icon'
																	style={{ color: '#607D8B', fontSize: '18px' }}
																/>
																<span className='text-dark fw-bold  d-block mb-1 fs-6'>
																	{actualIndex}
																</span>
															</div>
														</td>
														<td>
															<span className='text-dark fw-bold  d-block mb-1 fs-6'>
																{member.document_type}
															</span>
														</td>
														<td width={'50%'}>
															<span className='text-dark fw-bold  d-block mb-1 fs-6'>
																{isPdf ? (
																	<>
																		<a
																			href={fileUrl}
																			target='_blank'
																			rel='noopener noreferrer'>
																			<embed
																				src={fileUrl}
																				className='w-75'
																			/>
																		</a>
																		<div>
																			<button
																				type='button'
																				onClick={() => window.open(fileUrl)}
																				className='btn btn-success mx-2 mt-md-7 my-4'>
																				View PDF
																			</button>
																		</div>
																	</>
																) : (
																	<a
																		href={fileUrl}
																		target='_blank'
																		rel='noopener noreferrer'>
																		<img
																			src={fileUrl}
																			className='w-50'
																			alt='Image preview'
																		/>
																	</a>
																)}
															</span>
														</td>
													</tr>
													{isVisible && (
														<tr className={`detail-row ${isVisible ? 'is-visible' : ''}`}>
															<td colSpan={12}>
																<div>
																	<strong>{sortableFields[0].title}:</strong> {member.document_type}
																</div>
															</td>
														</tr>
													)}
												</React.Fragment>
											)
										}}
										visibleDetails={visibleDetails}
										pagination={pagination}
										setPagination={setPagination}
										loading={loading}
									/>
								</div>
								{memberData.length === 0 && !loading && (
									<div className='d-flex text-center w-100 align-content-center justify-content-center mt-5'>
										<b>No records found</b>
									</div>
								)}
								{memberData.length > 0 && (
									<UsersListPagination
										totalPages={Math.ceil(memberData.length / pagination.itemsPerPage)}
										currentPage={pagination.page}
										onPageChange={handlePageChange}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	)
}

export { HRMemberDetail }
