import { getAPIHeaders } from '../../../_metronic/helpers/AuthToken'
import { APIDelete, APIGet, APIPatch, APIPost } from '../../../_metronic/helpers/Utils'
import * as FGGroupEndpoints from '../../constants/fg_group_endpoints'

export function AddInptaListing(body?: {
	business_type?: string
	business_name?: string
	description?: string
	business_category?: [string]
	business_logo?: string
	business_images?: [string]
	services?: [string]
	tags?: [string]
	social_media?: [
		{
			social_media_type: string
			link: string
		}
	]
	locations?: [
		{
			location_name: string
			address_line_1: string
			address_line_2: string
			city: string
			state: string
			country: string
			pin_code: string
			landmark: string
			direction_link: string
			contact: {
				contact_type: string
				value: string
			}
		}
	]
	contacts?: [
		{
			contact_type: string
			value: string
		}
	]
	faqs?: [
		{
			question: string
			answer: string
			additional_links: [string]
		}
	]
	timings?: [
		{
			title: string
			timings: [
				{
					from_time: string
					to_time: string
				}
			]
		}
	]
}): Promise<FGGroupAPIResponse> {
	return APIPost(FGGroupEndpoints.AddInptaListing, getAPIHeaders('fg_group'), undefined, body)
}

export function UpdateInptaListing(body?: {
	listing_id?: string
	owner_id?: string
	business_type?: string
	business_name?: string
	description?: string
	business_category?: [string]
	business_logo?: string
	business_images?: [string]
	services?: [string]
	tags?: [string]
	social_media?: [
		{
			social_media_type: string
			link: string
		}
	]
	locations?: [
		{
			location_name: string
			address_line_1: string
			address_line_2: string
			city: string
			state: string
			country: string
			pin_code: string
			landmark: string
			direction_link: string
			contact: {
				contact_type: string
				value: string
			}
		}
	]
	contacts?: [
		{
			contact_type: string
			value: string
		}
	]
	faqs?: [
		{
			question: string
			answer: string
			additional_links: [string]
		}
	]
	timings?: [
		{
			title: string
			timings: [
				{
					from_time: string
					to_time: string
				}
			]
		}
	]
}): Promise<FGGroupAPIResponse> {
	return APIPatch(FGGroupEndpoints.UpdateInptaListing, getAPIHeaders('fg_group'), undefined, body)
}

export function RemoveInptaListing(query: { listing_id: string }): Promise<FGGroupAPIResponse> {
	return APIDelete(FGGroupEndpoints.RemoveInptaListing, getAPIHeaders('fg_group'), undefined, query)
}

export function GetListingForInptaAdmin(
	query?: {
		listing_id?: string
		page?: number
		limit?: number
		approval_status?: string
	} & FGGroupSearchOptions &
		FGGroupPaginationOptions &
		FGGroupSortOptions
): Promise<FGGroupAPIResponse> {
	return APIGet(FGGroupEndpoints.GetAdminInptaListing, getAPIHeaders('fg_group'), query)
}

export function GetInptaListing(
	query?: {
		listing_id?: string
		page?: number
		limit?: number
		approval_status?: string
	} & FGGroupSearchOptions &
		FGGroupPaginationOptions &
		FGGroupSortOptions
): Promise<FGGroupAPIResponse> {
	return APIGet(FGGroupEndpoints.GetInptaListing, getAPIHeaders('fg_group'), query)
}

export function ListingActionInpta(body: {
	listing_id: string
	approval_status: string
	comment?: string
}): Promise<FGGroupAPIResponse> {
	return APIPatch(FGGroupEndpoints.ListingActionInpta, getAPIHeaders('fg_group'), undefined, body)
}
